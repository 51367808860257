import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { processFile, checkTaskStatus } from './api'; // Import the updated API functions
import './ProcessingPage.css'; // Import the updated CSS

const ProcessingPage = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [taskId, setTaskId] = useState(null); // State to hold the task ID

    useEffect(() => {
        const fileId = localStorage.getItem('fileId'); // Retrieve fileId from localStorage

        if (!fileId) {
            console.error('No fileId found in localStorage.');
            navigate('/upload'); // Redirect to UploadPage if fileId is missing
            return;
        }

        const startProcessing = async () => {
            try {
                const taskResponse = await processFile(fileId); // Start file processing and get task ID
                setTaskId(taskResponse.task_id); // Store task ID in state
            } catch (error) {
                console.error('Error processing file:', error);
                setError('Error starting file processing. Please try again later.');
            }
        };

        startProcessing(); // Start processing on component mount
    }, [navigate]);

    useEffect(() => {
        console.log("Current taskId:", taskId); // Debugging line
        if (!taskId) return; // If task ID is not set, do nothing

        const pollStatus = async () => {
            console.log("Polling")
            try {
                const statusResponse = await checkTaskStatus(taskId); // Call the API to check task status
                if (statusResponse.status === 'complete') {
                    // Redirect to results page when the status is successful
                    navigate('/results');
                } else if (statusResponse.status === 'error') {
                    // Handle the case when there's an error in processing
                    setError('Error processing the file. Please try again.');
                }
            } catch (error) {
                console.error('Error checking task status:', error);
                setError('Error checking task status. Please try again later.');
            }
        };

        // Start the polling process
        const intervalId = setInterval(pollStatus, 2000); // Check every 2 seconds

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [taskId, navigate]); // Add taskId as a dependency to useEffect

    return (
        <div className="processing-page-body">
            <div className="processing-page-container">
                <h1 className="processing-page-heading">Analyzing Your Data...</h1>
                <div className="processing-page-spinner"></div>
                <p className="processing-page-paragraph">
                    We are analyzing your spreadsheet to extract valuable insights.
                </p>
                {error && <p className="error-message">{error}</p>} {/* Display error message if any */}
            </div>
        </div>
    );
};

export default ProcessingPage;
